<template>
  <div 
  class="one" 
  :style="{width:'100%',paddingLeft:info.treeIn?0:'10px'}">
        <p class='item'>
            <i :class="info.icon?`iconfont ${info.icon} img`:'img'"></i>
            <span :class="info.icon?'':'textColor'">{{info.name}}</span>
            <span style="color:orange" v-if="info.size"> ({{info.size}})</span>
        </p>
        <span
        @click="addTag" 
        v-if="info.plus" 
        class="el-icon-plus"></span>
        <el-dropdown 
        v-if="!info.plus"  
        placement='bottom' 
        trigger="click" 
        :hide-on-click='false'>
            <span 
            class="iconfont icon-gengduo more" 
            @click.stop=''></span>
            <el-dropdown-menu slot="dropdown">
                <template  v-if="info.type=='control'">
                    <el-dropdown-item
                    @click.native.stop="screen(index)"
                    v-for="(item,index) in commonList"
                    :key="index">
                    <span style="color:#06AEE5;display:inline-block;width:10px" :class="item.icon?'iconfont icon-duihao1':''"></span>
                    {{item.name}}
                    </el-dropdown-item>
                </template>
                <template v-else>
                    <el-dropdown-item @click.native="readed">全部已读</el-dropdown-item>
                    <!-- <el-dropdown-item>新建文件夹</el-dropdown-item> -->
                </template>
            </el-dropdown-menu>
        </el-dropdown>
  </div>
</template>

<script>
export default {
    props:{
        info:{
            default:{},
            required:true
        },
    },
    data(){
      return {
          commonList:[
              {name:'所有收件箱',icon:true},
              {name:'所有发件箱',icon:true}
          ]
      }; 
    },
    methods:{
        readed(info){
            this.$emit('setReaded',{...this.info,type:'account'})
        },
        screen(index){
            this.commonList[index].icon=!this.commonList[index].icon
            // const arr = []
            // this.commonList.forEach((item,ind)=>{
            //     if(item.icon) arr.push(item)
            // })
            // console.log(arr);
            // this.$emit('commonControl',arr)
        },
        addTag(){
            console.log('dadsad');
            this.$emit('showTagBox')
        }
    }
}
</script>

<style scoped lang='scss'>
.item{
    display: flex;
    align-items: center;
}
p,span{
    font-size: 14px;
    color: #606266;
    margin: 0 3px;
}
.one{
    padding: 6px 0 6px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.img .iconfont{
    font-size: 20px;
}
.one:hover{
    background: #f2f2f2;
    .more{
        display: block;
    }
}
.more{
    display: none;
    float: right;
}
.more:hover{
    font-weight: bold;
}
ul{
    line-height: 28px;
}
.textColor{
    color: #999999;
}
</style>